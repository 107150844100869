<!-- eslint-disable vue/html-closing-bracket-newline -->
<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <div>
    <v-row>
      <!-- TABULKA S KONTAKTMI -->
      <v-col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <v-card>
          <!-- btn DISTR. ZOZNAM -->
          <v-btn
            :loading="loadingDist"
            type="button"
            class="v-btn v-btn--absolute v-btn--fab v-btn--has-bg v-btn--right v-btn--round v-btn--top theme--light v-size--small primary"
            title="Seznam dist. seznamů"
            style="left:15px;background-color: #AD1457 !important;"
            @click="showDistList()">
            <v-icon style="color:white">{{ icons.mdiAccountDetailsOutline }}</v-icon>
          </v-btn>
          <!-- btn odkaz na SVK tel. zoznam -->
          <v-btn
            :loading="loadingDist"
            small
            type="button"
            class="v-btn v-btn--absolute v-btn--fab v-btn--has-bg v-btn--right v-btn--round v-btn--top theme--light v-size--small primary"
            title="Kompletní tabulka pro ČR a SR..."
            style="left:75px;top:-12px;background-color: #14ad66 !important;"
            @click="linkSVKTelZoznam()">
            <v-icon style="color:white">{{ icons.mdiBookAccountOutline }}</v-icon>
          </v-btn>
          <!-- btn SYNC -->
          <v-btn
            v-show="$store.getters.getProfile.is_admin && contacts && contacts.length > 0"
            :loading="loadingS"
            type="button"
            class="v-btn v-btn--absolute v-btn--fab v-btn--has-bg v-btn--right v-btn--round v-btn--top theme--light v-size--small primary"
            title="Synchronizace ditribučních seznamů"
            style="margin-right:60px;"
            @click="syncZimbraDistList()"
          >
            <v-icon style="color:white">{{ icons.mdiSync }}</v-icon>
          </v-btn>
          <!-- btn EXPORT -->
          <button
            v-if="contacts && contacts.length > 0"
            type="button"
            class="v-btn v-btn--absolute v-btn--fab v-btn--has-bg v-btn--right v-btn--round v-btn--top theme--light v-size--default primary"
            title="Export do excelu"
            @click="exportToCsv()"
          >
            <span
              class="v-btn__content"
            >
              <span
                aria-hidden="true"
                class="v-icon notranslate theme--light"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  role="img"
                  aria-hidden="true"
                  class="v-icon__svg"
                >
                  <path
                    d="M12,1L8,5H11V14H13V5H16M18,23H6C4.89,23 4,22.1 4,21V9A2,2 0 0,1 6,7H9V9H6V21H18V9H15V7H18A2,2 0 0,1 20,9V21A2,2 0 0,1 18,23Z"
                  >
                  </path>
                </svg>
              </span>
            </span>
          </button>
        </v-card>
        <v-card
          :loading="loading"
          :style="`height:${desktop_h - 160}px;padding:10px`"
        >
          <div>
            <!-- LOADING -->
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <!-- TABULKA -->
            <v-simple-table
              v-if="contacts && contacts.length > 0"
              style="margin-top:10px;"
              :height="`${desktop_h - 190}px`"
              fixed-header
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(column, index) in headers"
                      :key="index"
                      class="text-left"
                    >
                      {{ column.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="contact in contacts"
                    :key="contact._id"
                    :class="selected_row === contact._id ? $vuetify.theme.dark ? 'selected-row-dark-theme' : 'selected-row' : ''"
                    style="cursor:pointer;"
                    @click="contactDetail(contact._id)"
                  >
                    <td>{{ contact.firstname }}</td>
                    <td>{{ contact.surname }}</td>
                    <td>{{ contact.jobTitle }}</td>
                    <td>{{ contact.phone }}</td>
                    <td>{{ contact.city }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- NIC SA NENASLO -->
            <div v-else>
              <p style="text-align: center;margin-top:20px;">Je nám to líto ale jste dost náročný, zadaným kritériím nevyhovuje žádný záznam v naší DB, zkuste polevit ve svých požadavcích :)</p>
              <p style="text-align: center;margin-top:20px;">Hledaná fráze <b>"{{ $store.state.global.filter_search }}"</b></p>
            </div>
          </div>
        </v-card>
      </v-col>
      <!-- DETAIL KONTAKTU -->
      <!-- <v-col
        xs="12"
        sm="5"
        md="5"
        lg="5"
        xl="5"
      >
      </v-col> -->
    </v-row>

    <!-- DETAIL KONTAKTU -->
    <transition name="slide-fade">
      <v-card
        v-show="show_detail"
        :style="`z-index: 5;width: 700px; position: absolute; top:${detail_top}px; right:${detail_right}px;background-color: #FAFAFA;`"
        :loading="loadingD"
      >
        <v-icon
          style="position: absolute; top:20px; right:20px; cursor:pointer;"
          @click="show_detail=false">
          {{ icons.mdiClose }}
        </v-icon>
        <div style="margin-top: 15px;">
          <v-avatar
            style="float:left;"
            rounded
            size="190"
            class="me-6"
          >
            <v-img
              v-if="contact_detail.foto"
              :src="contact_detail.foto"
            ></v-img>
            <v-img
              v-else-if="isWoman(contact_detail.surname)"
              :src="require('@/assets/images/avatars/2.png')"
            ></v-img>
            <v-img
              v-else
              :src="require('@/assets/images/avatars/1.png')"
            ></v-img>
          </v-avatar>
          <div style="float:left;">
            <h2 style="color:#A26BFD !important">{{ contact_detail.firstname }} {{ contact_detail.surname }}</h2>
            <p style="font-size:18px">
              {{ contact_detail.jobTitle }}
            </p>

            <div>{{ contact_detail.phone }}</div>
            <div v-if="contact_detail.phoneline">
              klapka: {{ contact_detail.phoneline }}
            </div>
            <div>{{ contact_detail.email }}</div>
          </div>
        </div>

        <div style="clear:both;margin-bottom:20px;"></div>

        <v-divider></v-divider>

        <v-card-text>
          <v-card
            style="margin-top:0px;background-color: #FAFAFA;"
            elevation="0"
          >
            <div
              v-if="contact_detail.personalNR"
              style="margin-bottom:20px"
            >
              Osobní číslo: {{ contact_detail.personalNR }}
            </div>

            <h4 style="color:#A26BFD !important;">{{ contact_detail.city }}</h4>
            <div style="padding-left:20px">
              <div>{{ contact_detail.address }}</div>
            </div>

            <h4
              v-show="contact_detail.dist_list && contact_detail.dist_list.length > 0"
              style="color:#A26BFD !important;margin-top:20px">
              Distribuční seznamy do kterých je uživatel přiřazen
            </h4>
            <div style="padding-left:20px;">
              <div
                v-for="(dist_list, index) in contact_detail.dist_list"
                :key="index">
                <div style="text-transform:capitalize;"><b>{{ dist_list.name.length > 0 ? dist_list.name : dist_list.mail[0].toString().replace("@indexnoslus.cz", "") }}</b></div>
                <div style="padding-left:20px"><i>{{ dist_list.mail.toString() }}</i></div>
              </div>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </transition>

    <!-- ZOZNAM DIST. ZOZNAMOV -->
    <transition name="slide-fade">
      <v-card
        v-show="show_dist"
        :style="`z-index: 5;width: 700px; position: absolute; top:${detail_top}px; right:${detail_right}px;background-color: #FAFAFA;max-height: 700px;overflow-y: scroll;`"
        :loading="loadingDist"
      >
        <v-icon
          style="position: absolute; top:20px; right:20px; cursor:pointer;"
          @click="show_dist=false">
          {{ icons.mdiClose }}
        </v-icon>

        <div style="margin-top: 15px;padding:5px;">
          <b>Distribuční seznamy</b>
        </div>

        <div style="clear:both;margin-bottom:20px;"></div>

        <v-divider></v-divider>

        <v-card-text>
          <v-card
            style="margin-top:0px;background-color: #FAFAFA;"
            elevation="0">
            <div
              v-for="item in dist_lists"
              :key="item.uid"
              style="border-bottom: 1px dotted #E1BEE7;">
              <div class="truncate">
                <b style="color:#A26BFD">
                  <span
                    style="cursor:pointer;"
                    @click="fetchMembersDistList(item.mail[0])">
                    {{ item.name || item.mail[0].replace('@indexnoslus.cz', '').toUpperCase() }}
                  </span>
                </b>
                <i
                  style="font-size: 10px;"
                  :title="item.mail.toString()">
                  ({{ item.mail.toString() }})
                </i>
              </div>
              <div style="margin-left: 20px;font-size: 10px;">
                založeno: {{ stampTimeDate(item.zimbraCreateTimestamp) }} | stav: {{ item.zimbraMailStatus === 'enabled' ? 'aktívní' : 'neaktívní' }} | členov: {{ item.members.length }}
              </div>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </transition>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: ["off", { "allow": ["__place"] }] */
import {
  mdiSync,
  mdiClose,
  mdiAccountDetailsOutline,
  mdiBookAccountOutline,
} from '@mdi/js'
import axios from 'axios'
import latinize from 'latinize'
import csvDownload from 'json-to-csv-export'
import SnackBar from '@/components/snackbar/index.vue'
import response from '@/mixins/response'

// eslint-disable-next-line import/no-cycle
import { eventBus } from '../../../main'

export default {
  components: {
    'snack-bar': SnackBar,
  },
  mixins: [response],
  data() {
    return {
      icons: {
        mdiSync,
        mdiClose,
        mdiAccountDetailsOutline,
        mdiBookAccountOutline,
      },
      loading: true,
      loadingD: true,
      loadingS: false,
      loadingDist: false,
      desktop_w: 1024,
      desktop_h: 768,
      snackbar: false,
      snack_color: 'red',
      snack_text: '',
      headers: [
        {
          text: 'Jméno',
          align: 'start',
          sortable: false,
          value: 'firstname',
        },
        {
          text: 'Příjmění',
          align: 'start',
          sortable: false,
          value: 'surname',
        },
        { text: 'Pozice', value: 'jobTitle' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Pobočka', value: 'city' },
      ],
      contacts: [],
      dist_lists: [],
      contact_detail: {},
      selected_row: undefined,
      fetch_settings: {
        filter: {
          active: true,
        },
        columns: {
          firstname: 1,
          surname: 1,
          jobTitle: 1,
          phone: 1,
          city: 1,
          email: 1,
        },
        paging: {
          limit: 999,
          skip: 0,
          current_page: 1,
          total_pages: 1,
          total_records: 1,
        },
        sort: {
          latin_surname: 1,
        },
      },
      show_detail: false,
      show_dist: false,
      detail_right: 160,
      detail_top: 117,
    }
  },
  watch: {
    '$store.state.global.filter_search': {
      handler(_newVal) {
        const filter = _newVal ? this.buildFilter(latinize(_newVal).toLowerCase()) : this.buildFilter(_newVal)
        this.fetchTelSeznam(filter)
      },
      immediate: true,
      deep: true,
    },
    snackbar(new_val) {
      if (new_val === true) {
        setTimeout(() => {
          this.snackbar = false
        }, 10000)
      }
    },
    show_detail(new_val) {
      if (new_val) {
        this.show_dist = false
      }
    },
    show_dist(new_val) {
      if (new_val) {
        this.show_detail = false
      }
    },
  },
  created() {
    window.addEventListener('resize', this.windowResize)
    window.addEventListener('wheel', this.handleWheel)
    this.windowResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize)
    window.removeEventListener('wheel', this.handleWheel)
  },
  mounted() {
    this.fetchTelSeznam()
  },
  methods: {
    windowResize() {
      this.desktop_w = window.innerWidth
      this.desktop_h = window.innerHeight
      if (this.desktop_w >= 1920) {
        this.detail_top = 117
        this.detail_right = 160
      } else if (this.desktop_w > 1680) {
        this.detail_top = 117
        this.detail_right = 140
      } else if (this.desktop_w >= 1440) {
        this.detail_right = 50
        this.detail_top = 117
      } else if (this.desktop_w >= 1280) {
        this.detail_right = 40
        this.detail_top = 117
      } else if (this.desktop_w > 0) {
        this.detail_right = 0
        this.detail_top = -55
      }
    },
    handleWheel() {
      this.show_detail = false
    },
    buildFilter(_search) {
      const filter = { // default
        active: true,
      }
      if (_search && _search.length > '') {
        const search = { $regex: `${_search}`, $options: 'i' }
        filter.$or = [
          { latin_firstname: search },
          { latin_surname: search },
          { phone: search },
          { email: search },
          { latin_jobTitle: search },
          { latin_city: search },
          { latin_address: search },
        ]
      }
      return filter
    },
    fetchTelSeznam(p_filter) {
      this.loading = true
      this.show_detail = false
      this.show_dist = false
      this.fetch_settings.filter = p_filter || this.fetch_settings.filter
      const body = JSON.stringify(this.fetch_settings)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/telseznam/index`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.contacts = resp.data.db_data
          if (this.contacts.length > 0) {
            // const id = this.contacts[0]._id
            // this.contactDetail(id)
          }
          this.loading = false
          this.fetch_settings.paging.total_pages = resp.data.total_pages
        })
        .catch(err => {
          this.loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    contactDetail(p_id) {
      /* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
      this.selected_row = p_id
      this.loadingD = true
      this.show_detail = true
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/telseznam/${p_id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          this.contact_detail = resp.data.db_data[0]
          this.loadingD = false
          this.fetch_settings.paging.total_pages = resp.data.total_pages
        })
        .catch(err => {
          this.loadingD = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    isWoman(p_val) {
      /* eslint newline-before-return: "off" */

      if (p_val) {
        if (p_val.slice(p_val.length - 1) === 'á') {
          return true // iba ak je zena
        }
      }
      return false // default
    },
    exportToCsv() {
      try {
        const kontakty = []
        for (let i = 0; i < this.contacts.length; i += 1) {
          kontakty.push({
            jméno: this.contacts[i].firstname,
            příjmení: this.contacts[i].surname,
            funkce: this.contacts[i].jobTitle,
            telefon: this.contacts[i].phone,
            adresa: this.contacts[i].city,
          })
        }
        csvDownload(kontakty, 'kontakty_index.csv', ';')
      } catch (err) {
        console.error(err)
      }
    },
    syncZimbraDistList() {
      /* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
      this.loadingS = true
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/telseznam/zimbra_dist_list/sync`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          console.log(resp)
          this.loadingS = false
          this.snack_text = 'Prebieha synchronizácia dist. zoznamov z mailserveru CZ-PLUTO...'
          this.snack_color = 'green'
          this.snackbar = true
        })
        .catch(err => {
          this.loadingS = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    showDistList() {
      /* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
      this.show_dist = true
      this.loadingDist = true
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/telseznam/zimbra_dist_list/list`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          this.loadingDist = false
          this.dist_lists = resp.data.db_data
          console.log(this.dist_lists)
        })
        .catch(err => {
          this.loadingDist = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    stampTimeDate(p_date) {
      return this.$moment(p_date, 'YYYYMMDDHHmmsss').format('DD.MM.YYYY HH:mm') || p_date
    },
    fetchMembersDistList(p_name) {
      eventBus.$emit('set-FilterSearch', p_name)
    },
    linkSVKTelZoznam() {
      window.open('https://docs.google.com/spreadsheets/d/1eftEzHYfXu8sg_usW7gE7k0rQPq4KMQ-7zdrEJ7Xjw0/edit#gid=995868811', '_blank')
    },
  },
}
</script>

<style>
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.selected-row {
  background-color: #f2f2f2;
  opacity: 0.9;
  font-weight: bold;
}
.selected-row-dark-theme {
  background-color: #2d2949;
  opacity: 0.9;
  font-weight: bold;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(600px);
  opacity: 0;
}
.truncate {
  width: 650px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
